@tailwind base;
@tailwind components;
@tailwind utilities;

.card:hover .icon {
    display: block;
}

.header {
    z-index: 99;
    height: 70px;
}

.icon {
    display: none;
}

input{
    font-size: 14px !important;
}

.bi-embedded {
    display: inline-block;
    width: 100% !important;
    height: calc(100vh - 70px);
    vertical-align: -0.125em;
    fill: currentColor;
    overflow: hidden;
}

.active{
    background-color: #4797CE;
}

.home {
    background-image: url("../src/assets/ticketboatMain.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.img {
    width: 330px;
}

.img img {
    width: 100%;
    height: 100%;
    background-size: cover;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border-left: 1px solid #fff;
    background-color: #4797CE;
}